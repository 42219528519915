a {
    cursor: pointer !important;
}

.table-custom > tbody p {
    margin-bottom: 0px;
    font-weight: bold;
}


.table-custom > tbody > tr > td {
    font-weight: normal !important;
}

.main-sidebar .nav .nav-item .nav-link i {
    font-size: 1.125rem;
    top: -1px;
}

.clickable-row {
    cursor: pointer;
}

.modal-backdrop.fade {
    opacity: .5 !important;
}

.main-sidebar {
    z-index: 1039 !important;
}

.modal.in {
    display: block!important;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 1 !important;
}

.modal-dialog {
    margin-top: 15% !important;
}

.form-group .react-datepicker-wrapper {
    display: block;
}

.form-group .react-datepicker__input-container {
    width: 100%;
}


.profile-user {
    position: relative;
    min-height: 250px;
    margin-bottom: 30px;
}

.profile-user .btn-group.open .dropdown-toggle {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.profile-user .btn-profile {
    position: absolute;
    right: 10px;
}

.profile-wrapper img {
    width: 100px;
}

.profile-wrapper .title {
    font-weight: 700;
    font-size: 1.2em;
    color: #3d9cdb;
}

.bottom-profile {
    margin-top: 20px;
}

.bottom-profile .text-small {
    font-size: .8em;
}

.img-circle {
    border-radius: 50%;
}

.btn-transparent {
    background-color: transparent;
    float: right;
}

button:focus {
    outline:0 !important;
}

.page-link:focus {
    box-shadow: none !important;
}

.css-1pcexqc-container {
    font-weight: 200 !important;
    font-size: .8125rem !important;
}

.css-lszy77t-control:hover {
    border: none !important;
    color: #495057;
    background-color: #fff;
    border-color: #007bff;
    box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06)
}

.css-lszy77t-control {
    border: none !important;
    color: #495057;
    background-color: #fff;
    border-color: #007bff;
    box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06)
}

.css-kj6f9i-menu {
    z-index: 999 !important;
    cursor: pointer !important;
}

.list-group-flush {
    width: 100%;
}

/* profile */

.profile-card {
    padding: 40px 30px;
    text-align: center;
    background-color: #fff;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
    border-radius: 8px;
}

.profile-card img {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    border: 2px solid #e1e1e1;
    object-fit: cover;
}

.profile-card h2 {
    font-size: 1.5em;
    margin: 0;
    font-weight: 500;
    color: #3d5170;
}

.profile-card p {
    margin-bottom: 5px;
}

.css-1a57z3m-control {
    cursor: pointer !important;
}

.messagebox {
    position: absolute;
    z-index: 9999;
    box-shadow: 5px 3px 30px rgba(29, 29, 29, 0.17);
    background: #fff;
    padding: 30px;
    top: 0px;
    margin: 5% 25%;
    border: 1px solid #e1e1e1;
}

.backdrop {
    position: fixed;
    background-color: #33333342;
    width: 100%;
    height: 100%;
    z-index: 1040;
    top: 0;
    left: 0;
}

.css-e110bw {
    z-index: 1039 !important;
}

.css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    font-size: 13px !important;
    font-weight: normal !important;
}

.auth-form__logo_custom {
    width: 5rem !important;
}
.main-logo-custom {
    width: 100% !important;
}

.main-navbar__search .brand {
    display: block;
    padding: 15px 20px;
    font-size: 1.5em;
}

.main-sidebar .nav .nav-item .nav-link {
    padding: .6rem 1rem !important;
}


.table-custom th {
    position: relative;
    cursor: pointer;
    padding-right: 35px !important;
  }
  
  .table-sorting {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ccc;
  }
  
  .table-sorting.active {
    color: #007bff;
  }
  
  .table-custom td p {
    margin-bottom: 0px !important;
  }
  
  .table-custom td div {
    margin-top: 10px;
  }
  
  .table-custom td div button:first-child {
    margin-right: 5px;
  }
  
  .table-scrollable tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
  }

  .table-scrollable thead tr, .table-scrollable tfoot tr {
    display:block;
  }

  .table-scrollable tr > th, .table-scrollable tr > td {
    width: 33.3%;
  }

  .table-scrollable tr > td {
      font-weight: normal;
      position: relative;
  }

  .table-scrollable tr > td:last-child {
      padding-right: 35px;
  }

  .table-scrollable tr > td > button.btn-remove {
      position: absolute;
      right: 0px;
      top: 5px;
  }


  .img-small {
      height: 100px;
      object-fit: cover;
  }

  .card-clickable {
      cursor: pointer;
  }

  .card-clickable .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-clickable:hover .card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 123, 255, .1),
    0 0.9375rem 1.40625rem rgba(0, 123, 255,.1),
    0 0.25rem 0.53125rem rgba(0, 123, 255,.12),
    0 0.125rem 0.1875rem rgba(0, 123, 255,.1);
    color: #007bff !important
  }

.autocomplete {
    position: absolute;
    width: 94%;
    z-index: 99;
    background: #fff;
    cursor: pointer;
    font-weight: normal;
}

.autocomplete span {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border: 1px solid #e1e1e1;
}

.autocomplete span:hover {
    background-color: #007afd;
    color: #fff;
    border: #007afd;
}

.modal-dialog {
    margin-top: 30px !important;
}

.table-scrollable thead > tr > th {
    border-top: none;
}

.Select-menu-outer {
    z-index: 999 !important;
}

@page {
    size: 90mm 55mm;
}

@media print {
    @page { margin: 0; }
    html, body {
        padding: 5mm;
    }
}

.dropdown-left-manual {
    right: 0;
    left: auto;
    padding-left: 1px;
    padding-right: 1px;
}

.is-invalid-select > div {
    border-color: #c4183c !important;
}

.table-responsive {
    width: 100% !important;
    overflow-x: auto;
    display: block;
}

table {
    font-size: .8125rem !important;
}

.table-custom th:first-child {
    min-width: 180px !important
}

.menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 20px;
}

.menu-list li {
    border: 1px solid #e1e1e1;
    padding: 10px;
    margin: 10px 0px;
}
.menu-wrapper {
    min-height: 600px;
}

.table-dashboard {
    font-size: 2em !important;
}

.table-black > tbody > tr > td {
    background-color: #000;
    color: #fff;
    height: 70px;
}

.table-black > thead > tr > th {
    background-color: orange;
    color: #fff;
}


.table-blue > tbody > tr > td {
    color: #fff;
    height: 70px;
}

.table-blue > tbody > tr:nth-child(odd) > td {
    background-color: #000;
}

.table-blue > tbody > tr:nth-child(even) > td {
    background-color: #000;
}

.table-blue > thead > tr > th {
    background-color: #000;
    color: #fff;
}

.msgbox-qr {
    width: 300px;
    padding: 10px;
    border-radius: 10px;
}

.modal-qr {
    position: relative;
}

@media(max-width: 500px) {
    .messagebox {
        margin: 5% 15%;
    }
}

.loading-autocomplete {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgb(255 255 255 / 48%);
}

.autocomplete-wrapper {
    z-index: 4;
    background-color: #fff;
    width: 100%;
    border: 1px solid #e1e1e1;
    margin-top: -1rem;
    border-radius: 0.2rem;
}

.autocomplete-wrapper > ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.autocomplete-wrapper > ul > li {
    padding: .5rem;
    cursor: pointer;
}

.autocomplete-wrapper > ul > li:hover {
    background-color: #007afd;
    color: #fff;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100% !important;
}

.no-wrap {
    white-space:nowrap;
}